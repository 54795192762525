<template>
  <div class="TableProfit">
    <div class="txt-333 font-w card-title">
      <div>各回收旧机利润排行</div>
      <div>
        <!-- <div
          :class="{ active: dateType == item.val }"
          v-for="item in tablist"
          :key="item.val"
          @click="dateType = item.val"
        >
          {{ item.name }}
        </div> -->
      </div>
    </div>
    <div>
      <profit :id="iiid" :metchtype="manufacturersType" :currdate="dateType" :partition="partition" @search="search"/>
    </div>
  </div>
</template>
<script>
import profit from "./table/profit";
export default {
  name: "TableProfit",
  components: { profit },
  props: ["partition", 'iiid',"manufacturersType"],
  data() {
    return {
      tablist: [
        { name: "今日", val: "01" },
        { name: "本周", val: "03" },
        { name: "本月", val: "05" },
      ],
      dateType: "01",
    };
  },
  created() {},
  methods: {
    search(e) {
      console.log(e);
      if (e) {
        this.dateType = "01";
      } else {
        this.dateType = "";
      }
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.TableProfit {
  width: 100%;
  min-height: 360px;
  background-color: white;
  > div:nth-child(2) {
    padding: 16px;
    box-sizing: border-box;
  }
}
.card-title {
  position: relative;
  padding-left: 16px;
  font-weight: bold;
  height: 57px;
  line-height: 57px;
  border-bottom: 1px solid #eff2f7;
  display: flex;
  justify-content: space-between;
  > div:nth-child(2) {
    display: flex;
    align-items: center;
    > div {
      font-size: 14px;
      font-family: FZLanTingHeiS-R-GB;
      font-weight: 400;
      color: #8492a6;
      margin: 0 15px;
      cursor: pointer;
    }
  }
}
.active {
  color: #20a0ff !important;
}
.card-title::before {
  position: absolute;
  content: " ";
  width: 4px;
  height: 18px;
  background: #4966d2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto 0;
}
</style>
